<script setup lang="ts">
import { ref } from 'vue'
import { Link, usePage } from '@inertiajs/vue3'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import Logo from '@/Components/Promo/icons/Logo.vue';

const page = usePage();

console.log(page.props.auth?.user);



const isMenuOpen = ref(false)
const isFeatureMenuOpen = ref(false)
const isSolutionsMenuOpen = ref(false)

const closeMenus = () => {
  isFeatureMenuOpen.value = false
  isSolutionsMenuOpen.value = false
  isMenuOpen.value = false
}

const toggleFeatureMenu = () => {
  isSolutionsMenuOpen.value = false
  isFeatureMenuOpen.value = !isFeatureMenuOpen.value
}

const toggleSolutionsMenu = () => {
  isFeatureMenuOpen.value = false
  isSolutionsMenuOpen.value = !isSolutionsMenuOpen.value
}
</script>

<template>
  <nav class="bg-surface border-b border-border">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex items-center">
          <Link href="/" class="text-2xl font-bold text-white" @click="closeMenus">
          <Logo class="h-10 w-auto" />
          </Link>
        </div>

        <!-- Desktop menu -->
        <div class="hidden md:flex items-center space-x-8">
          <!-- Features Dropdown -->
          <div class="relative" @mouseenter="isFeatureMenuOpen = true" @mouseleave="isFeatureMenuOpen = false">
            <button class="text-gray-300 hover:text-white py-2">Features</button>
            <div v-show="isFeatureMenuOpen"
              class="absolute left-0  w-48 overflow-hidden bg-surface border border-gray-700 rounded-md shadow-lg">
              <Link href="/features/audio-sharing" class="block px-4 py-2 text-sm text-gray-300 hover:bg-surface-light">
              Audio Sharing
              </Link>

              <Link href="/features/email" class="block px-4 py-2 text-sm text-gray-300 hover:bg-surface-light">
              Email
              </Link>
              <Link href="/features/crm" class="block px-4 py-2 text-sm text-gray-300 hover:bg-surface-light">
              CRM
              </Link>
              <Link href="/features/reporting" class="block px-4 py-2 text-sm text-gray-300 hover:bg-surface-light">
              Reporting
              </Link>
              <Link href="/features/coverage" class="block px-4 py-2 text-sm text-gray-300 hover:bg-surface-light">
              Coverage monitoring
              </Link>
              <Link href="/features/automation" class="block px-4 py-2 text-sm text-gray-300 hover:bg-surface-light">
              Automation
              </Link>
            </div>
          </div>

          <!-- Solutions Dropdown -->
          <div class="relative" @mouseenter="isSolutionsMenuOpen = true" @mouseleave="isSolutionsMenuOpen = false">
            <button class="text-gray-300 hover:text-white py-2">Solutions</button>
            <div v-show="isSolutionsMenuOpen"
              class="absolute left-0  w-48 overflow-hidden bg-surface border border-gray-700 rounded-md shadow-lg">
              <Link href="/solutions/artists" class="block px-4 py-2 text-sm text-gray-300 hover:bg-surface-light">
              For Artists
              </Link>
              <Link href="/solutions/publicists" class="block px-4 py-2 text-sm text-gray-300 hover:bg-surface-light">
              For Publicists
              </Link>
              <Link href="/solutions/labels" class="block px-4 py-2 text-sm text-gray-300 hover:bg-surface-light">
              For Labels
              </Link>
            </div>
          </div>

          <Link href="/pricing" class="text-gray-300 hover:text-white py-2">Pricing</Link>
          <div v-if="!page.props.auth?.user" class="flex items-center space-x-4">
            <Link href="/login" class="text-gray-300 hover:text-white py-2">Log in</Link>
            <Link href="/register" class="btn-primary">Start Your Free Trial</Link>
          </div>
          <div v-else class="flex items-center space-x-4">
            <Link v-if="page.props.auth?.user.type === 'admin'" href="/dashboard" class="btn-primary">Dashboard</Link>
            <Link v-if="page.props.auth?.user.type === 'contact'" href="/listener/dashboard" class="btn-primary">
            Dashboard</Link>
          </div>
        </div>

        <!-- Mobile menu button -->
        <div class="flex items-center md:hidden">
          <button class="text-gray-300" @click="isMenuOpen = !isMenuOpen">
            <Bars3Icon v-if="!isMenuOpen" class="h-6 w-6" />
            <XMarkIcon v-else class="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu -->
    <div v-show="isMenuOpen" class="md:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <!-- Features -->
        <button class="w-full text-left px-3 py-2 text-gray-300 hover:text-white py-2" @click="toggleFeatureMenu">
          Features
        </button>
        <div v-show="isFeatureMenuOpen" class="pl-4">
          <Link href="/features/audio-sharing" class="block px-3 py-2 text-sm text-gray-300 hover:text-white py-2">
          Audio Sharing
          </Link>

          <Link href="/features/email" class="block px-3 py-2 text-sm text-gray-300 hover:text-white py-2">
          Email
          </Link>
          <Link href="/features/crm" class="block px-3 py-2 text-sm text-gray-300 hover:text-white py-2">
          CRM
          </Link>
          <Link href="/features/reporting" class="block px-3 py-2 text-sm text-gray-300 hover:text-white py-2">
          Reporting
          </Link>
          <Link href="/features/coverage" class="block px-4 py-2 text-sm text-gray-300 hover:bg-surface-light">
          Coverage monitoring
          </Link>
          <Link href="/features/automation" class="block px-4 py-2 text-sm text-gray-300 hover:bg-surface-light">
          Automation
          </Link>
        </div>

        <!-- Solutions -->
        <button class="w-full text-left px-3 py-2 text-gray-300 hover:text-white py-2" @click="toggleSolutionsMenu">
          Solutions
        </button>
        <div v-show="isSolutionsMenuOpen" class="pl-4">
          <Link href="/solutions/artists" class="block px-3 py-2 text-sm text-gray-300 hover:text-white py-2">
          For Artists
          </Link>
          <Link href="/solutions/publicists" class="block px-3 py-2 text-sm text-gray-300 hover:text-white py-2">
          For Publicists
          </Link>
          <Link href="/solutions/labels" class="block px-3 py-2 text-sm text-gray-300 hover:text-white py-2">
          For Labels
          </Link>
        </div>

        <Link href="/pricing" class="block px-3 py-2 text-gray-300 hover:text-white py-2">
        Pricing
        </Link>
        <Link href="/login" class="block px-3 py-2 text-gray-300 hover:text-white py-2">
        Log in
        </Link>
        <a href="/register" class="block px-3 py-2 text-accent font-medium">Start Free Trial</a>
      </div>
    </div>
  </nav>
</template>
